import React, { useState, useRef, useEffect } from "react";
import "./AddEvent.css";
import EcoIndex from "./EcoIndex";
import MyEventsNavbar from "./MyEventsNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { postEventData, useApi } from "./apiService";
import { useNavigate } from "react-router-dom";
import CustomAlert from "./CustomAlert";
import "./assets/styles/font-awesome.css";
import AddEventNavbar from "./AddEventNavbar";
import { ConstructionOutlined } from "@mui/icons-material";
import { useEvent } from "./EventDetailsContext";
const NewForm = () => {
  const [isWasteDisposalChecked, setIsWasteDisposalChecked] = useState(false);
  const api = useApi();
  const navigate = useNavigate();
  const { updateEventDetails } = useEvent();
  const handleWasteDisposalChange = () => {
    setIsWasteDisposalChecked(!isWasteDisposalChecked);
  };

  const [formData, setFormData] = useState({
    name: "",
    type: "Conference",
    startDate: "",
    endDate: "",
    participantCount: "",
    //area: "",
    //areaUnit: "Sqft",
    mealsServed: {
      veg: true,
      nonVeg: false,
      vegan: false,
      alcohol: false,
    },
    mealsServedPerDay: 1,
    locationAddress: "",
    waterProvision: "PackagedBottle",
    emissionBoundary: {
      materialConsumption: false,
      organicWasteConverter: false,
      wasteDisposal: {
        value: false,
        subitem: {
          Recycler: false,
          Landfill: false,
        },
      },
    },

    mealProvided: true,
    isShuttleService: true,
    roomsBooked: "",
  });
  const locationRef = useRef(null);
  const [location, setLocation] = useState("");
  const [locationexist, setLocationExist] = useState(true);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (name === "wasteDisposal") {
        setFormData((prevData) => ({
          ...prevData,
          emissionBoundary: {
            ...prevData.emissionBoundary,
            wasteDisposal: {
              ...prevData.emissionBoundary.wasteDisposal,
              value: checked,
            },
          },
        }));
        handleWasteDisposalChange();
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
      }
    } else if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        waterProvision: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    // Ensure end date is not earlier than start date
    if (name === "startDate") {
      setFormData((prevData) => ({
        ...prevData,
        endDate: prevData.endDate < value ? value : prevData.endDate,
      }));
    }
  };

  const handleMealChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      mealsServed: {
        ...prevData.mealsServed,
        [name]: checked,
      },
    }));
  };

  const handleEmissionChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      emissionBoundary: {
        ...prevData.emissionBoundary,
        [name]: checked,
      },
    }));
  };

  const handleWasteDisposalSubitemChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      emissionBoundary: {
        ...prevData.emissionBoundary,
        wasteDisposal: {
          ...prevData.emissionBoundary.wasteDisposal,
          subitem: {
            ...prevData.emissionBoundary.wasteDisposal.subitem,
            [name]: checked,
          },
        },
      },
    }));
  };
  const [datevalidation, setDateValidation] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  console.log("location address", locationexist);

  useEffect(() => {
    const initializeAutocomplete = () => {
      const locationInput = locationRef.current;

      const autocomplete = new window.google.maps.places.Autocomplete(
        locationInput,
        {
          strictBounds: false,
        }
      );

      autocomplete.setFields([
        "formatted_address",
        "geometry.location",
        "name",
        "place_id",
      ]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setDateValidation("Please select a valid location before saving");
          setLocationExist(false);
          return;
        }
        setLocationExist(true);
        setDateValidation(null);
        const selectedText = locationInput.value;
        setLocation(selectedText);
        console.log("Selected place:", selectedText);
        setFormData((prevFormData) => ({
          ...prevFormData,
          locationAddress: selectedText,
        }));
      });
    };

    if (window.google && window.google.maps) {
      initializeAutocomplete();
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCpdevcXjKt9CVD1n8chB59MGW0d1uT2dg&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutocomplete;
      document.head.appendChild(script);
    }
  }, []);
  const handleSubmit = async (e) => {
    console.log(e);
    e.preventDefault();
    const startDate = new Date(formData.startDate);
    const endDate = new Date(formData.endDate);

    if (endDate < startDate) {
      setDateValidation("End date must be on or after start date.");
      return;
    }

    if (
      !formData.mealsServed.veg &&
      !formData.mealsServed.nonVeg &&
      !formData.mealsServed.vegan &&
      !formData.mealsServed.alcohol
    ) {
      setDateValidation("Please select at least one meal type.");
      return;
    }
    console.log("location address", locationexist);
    if (!locationexist) {
      setDateValidation("Please select a valid location before saving");
      return;
    }
    const mealsServedArray = [];
    if (formData.mealsServed.veg) mealsServedArray.push("Veg");
    if (formData.mealsServed.nonVeg) mealsServedArray.push("NonVeg");
    if (formData.mealsServed.vegan) mealsServedArray.push("Vegan");
    if (formData.mealsServed.alcohol) mealsServedArray.push("Alcohol");
    const mealType = mealsServedArray.join("|");

    const emissionBoundaryArray = [];
    if (formData.emissionBoundary.materialConsumption)
      emissionBoundaryArray.push("Material Consumption");
    if (formData.emissionBoundary.organicWasteConverter)
      emissionBoundaryArray.push("Organic Waste Converter");
    if (formData.emissionBoundary.wasteDisposal.value) {
      const wasteDisposalSubitems = Object.entries(
        formData.emissionBoundary.wasteDisposal.subitem
      )
        .filter(([key, value]) => value)
        .map(([key]) => key)
        .join(", ");
      emissionBoundaryArray.push(`WasteDisposal:{${wasteDisposalSubitems}}`);
    }
    const boundary = emissionBoundaryArray.join(", ");

    const requestData = {
      Name: formData.name,
      Type: formData.type,
      ParticipantCount: parseInt(formData.participantCount),
      MealType: mealType,
      LocationAddress: location,
      LocationId: 0,
      MealProvided: formData.mealProvided,
      isShuttleService: formData.isShuttleService,
      RoomsBooked: formData.roomsBooked,
      WaterProvision:
        formData.waterProvision === "PackagedBottle"
          ? "PackagedBottle"
          : "FilledOnSite",
      Boundary: boundary,
      StartDate: formData.startDate,
      EndDate: formData.endDate,
      MealServedCount: formData.mealsServedPerDay,
    };
    try {
      console.log("Data being sent to API:", requestData);
      const result = await postEventData(requestData);
      console.log("Event added successfully:", result);
      setSuccessMessage("Data saved successfully");
      const eventData = {
        boundary: result.boundary || "",
        createdBy: result.createdBy,
        createdOn: new Date(result.createdOn).toLocaleString(),
        endDate: result.endDate,
        eventId: result.id,
        eventName: result.name,
        eventType: result.type,
        isShuttleService: result.isShuttleService,
        location: location,
        locationId: result.locationId,
        mealCount: result.mealCount,
        mealProvided: result.mealProvided,
        mealType: result.mealType,
        myRole: "Event Manager",
        participantCount: result.participantCount,
        roomsBooked: result.roomsBooked,
        startDate: result.startDate,
        waterProvision: result.waterProvision,
      };
      updateEventDetails(eventData);
      handleConfirm(eventData);
      console.log("Result : ", eventData);
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };
  const handleConfirm = (event) => {
    console.log("State of eventData:", event);

    setTimeout(() => {
      navigate("/events/pre-event-planning/pre-planning", {
        state: { event: event },
      });
    }, 2000); // 2 seconds delay
  };

  console.log("Success Message : ", datevalidation);
  return (
    <>
      <div className="new-event-page">
        <EcoIndex />
        <div className="main-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 mb-3">
                <div class="main-content-header">ADD EVENT</div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-12 pt-3">
                <form
                  className="addForm"
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                >
                  <h2>Event Details</h2>
                  {datevalidation && (
                    <div
                      className="alert alert-danger d-flex align-items-center"
                      role="alert"
                    >
                      <div>{datevalidation}</div>
                    </div>
                  )}
                  {successMessage && (
                    <CustomAlert
                      message={successMessage}
                      onClose={() => setSuccessMessage(null)}
                      onConfirm={handleConfirm}
                    />
                  )}

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <div className="formdata">
                        <label>Event Name</label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="formdata">
                        <label>Type of Event</label>
                        <select
                          id="eventType"
                          name="type"
                          value={formData.type}
                          className="form-control"
                          onChange={handleChange}
                        >
                          <option value="conference">Conference</option>
                          <option value="corporate-event">
                            Corporate Event
                          </option>
                          <option value="live-show">Live Show</option>
                          <option value="exhibition">Exhibition</option>
                          <option value="sports-event">Sports Event</option>
                          <option value="tradeshow">TradeShow</option>
                          <option value="music-festival">Music Festival</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="formdata">
                        <label>Start Date </label>
                        <input
                          type="date"
                          name="startDate"
                          className="form-control"
                          value={formData.startDate}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="formdata">
                        <label>End Date</label>
                        <input
                          type="date"
                          name="endDate"
                          className="form-control"
                          value={formData.endDate}
                          min={formData.startDate}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-3">
                      <div className="formdata">
                        <label>Total Attendees</label>
                        <input
                          required
                          type="number"
                          min={0}
                          max={9999999999}
                          name="participantCount"
                          className="form-control"
                          value={formData.participantCount}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="formdata">
                        <label>
                          Number of Room Nights Booked
                          <span className="tooltip-icon">
                            <i
                              className="fa fa-info-circle"
                              aria-hidden="true"
                            />
                            <span className="tooltip-text">
                              Kindly note that if 100 rooms are booked for 2
                              nights, room nights will be 200.
                            </span>
                          </span>
                        </label>
                        <input
                          required
                          type="number"
                          min={0}
                          max={9999999999}
                          name="roomsBooked"
                          className="form-control"
                          value={formData.roomsBooked}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="formdata">
                        <label>Location of Event</label>
                        <div className="location-input-wrapper">
                          <input
                            required
                            className="form-control"
                            id="location"
                            ref={locationRef}
                            placeholder="Enter location"
                            value={location}
                            onChange={(e) => {
                              setLocation(e.target.value);
                              setLocationExist(false);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="Map_Iocn"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>
                        Emission Boundary
                        <span className="tooltip-icon">
                          <i className="fa fa-info-circle" aria-hidden="true" />
                          <span className="tooltip-text">
                            The Emission Boundary limits the tracking scope for
                            an event. Select the headers for which you wish to
                            track the carbon emissions
                          </span>
                        </span>
                      </h3>
                      <hr></hr>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 pt-2">
                      <div className="formdata">
                        <label>Are you providing meals to?</label>
                        <select
                          className="form-control"
                          name="mealProvided"
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              mealProvided: e.target.value === "true",
                            }))
                          }
                        >
                          <option value="true">All Attendees</option>
                          <option value="false">
                            Only Delegates and VVIPs
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3 pt-2">
                      <div className="formdata">
                        <label>
                          Are you providing Shuttle for Local travel?
                        </label>
                        <select
                          className="form-control"
                          name="isShuttleService"
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              isShuttleService: e.target.value === "true",
                            }))
                          }
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        {/* <label>Area of Event Space</label>
                              <div className="eventSpace">
                                <input type="number" className="form-control" name="area" value={formData.area} onChange={handleChange} required="true" min={0} max={9999999999} step="0.01" />
                                <select name="areaUnit" value={formData.areaUnit} className="form-control" onChange={handleChange}>
                                  <option value="Sqft">Sqft</option>
                                  <option value="Sqm">Sqm</option>
                                </select>
                              </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 pt-3">
                      <div className="formdata">
                        <label>Meals Served</label>
                        <div className="mainMealsServed">
                          <div className="MealsServed">
                            <input
                              type="checkbox"
                              id="Veg"
                              style={{ display: "none" }}
                              className="form-check-input"
                              name="veg"
                              checked={formData.mealsServed.veg}
                              onChange={handleMealChange}
                            />
                            <label htmlFor="Veg" className="event-check">
                              <div>
                                <svg
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 18 18"
                                >
                                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                  <polyline points="1 9 7 14 15 4"></polyline>
                                </svg>
                              </div>
                              <div style={{ textWrap: "nowrap" }}>
                                Vegetarian
                              </div>
                            </label>
                          </div>
                          <div className="MealsServed">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="NonVeg"
                              name="nonVeg"
                              style={{ display: "none" }}
                              checked={formData.mealsServed.nonVeg}
                              onChange={handleMealChange}
                            />
                            <label htmlFor="NonVeg" className="event-check">
                              <div>
                                <svg
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 18 18"
                                >
                                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                  <polyline points="1 9 7 14 15 4"></polyline>
                                </svg>
                              </div>
                              <div style={{ textWrap: "nowrap" }}>
                                Non-Vegetarian
                              </div>
                            </label>
                          </div>
                          <div className="MealsServed">
                            <input
                              type="checkbox"
                              id="Vegan"
                              name="vegan"
                              style={{ display: "none" }}
                              className="form-check-input"
                              checked={formData.mealsServed.vegan}
                              onChange={handleMealChange}
                            />
                            <label htmlFor="Vegan" className="event-check">
                              <div>
                                <svg
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 18 18"
                                >
                                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                  <polyline points="1 9 7 14 15 4"></polyline>
                                </svg>
                              </div>
                              <div style={{ textWrap: "nowrap" }}>Vegan</div>
                            </label>
                          </div>
                          <div className="MealsServed">
                            <input
                              type="checkbox"
                              id="Alcohol"
                              name="alcohol"
                              style={{ display: "none" }}
                              className="form-check-input"
                              checked={formData.mealsServed.alcohol}
                              onChange={handleMealChange}
                            />
                            <label htmlFor="Alcohol" className="event-check">
                              <div>
                                <svg
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 18 18"
                                >
                                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                  <polyline points="1 9 7 14 15 4"></polyline>
                                </svg>
                              </div>
                              <div style={{ textWrap: "nowrap" }}>Alcohol</div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 pt-3">
                      <div className="data formdata">
                        <label>Tick the checkbox to track emissions</label>
                        <div className="mainEmissionBoundary">
                          <div className="EmissionBoundary">
                            <input
                              type="checkbox"
                              id="materialConsumption"
                              style={{ display: "none" }}
                              name="materialConsumption"
                              class="form-check-input"
                              checked={
                                formData.emissionBoundary.materialConsumption
                              }
                              onChange={handleEmissionChange}
                            />
                            <label
                              htmlFor="materialConsumption"
                              className="event-check"
                            >
                              <div>
                                <svg
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 18 18"
                                >
                                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                  <polyline points="1 9 7 14 15 4"></polyline>
                                </svg>
                              </div>
                              <div style={{ textWrap: "nowrap" }}>
                                Material Consumption
                              </div>
                            </label>
                          </div>
                          <div className="EmissionBoundary">
                            <input
                              type="checkbox"
                              style={{ display: "none" }}
                              id="organicWasteConverter"
                              name="organicWasteConverter"
                              class="form-check-input"
                              checked={
                                formData.emissionBoundary.organicWasteConverter
                              }
                              onChange={handleEmissionChange}
                            />
                            <label
                              htmlFor="organicWasteConverter"
                              className="event-check"
                            >
                              <div>
                                <svg
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 18 18"
                                >
                                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                  <polyline points="1 9 7 14 15 4"></polyline>
                                </svg>
                              </div>
                              <div style={{ textWrap: "nowrap" }}>
                                Organic Waste converter on-site
                                <span className="tooltip-icon1">
                                  <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                  />
                                  <span className="tooltip-text1">
                                    Are you using Organic Waste Converters
                                    On-Site?
                                  </span>
                                </span>
                              </div>
                            </label>
                          </div>
                          <div className="EmissionBoundary">
                            <div className="SubEmissionBoundary">
                              <div className="optionBoundary mt-2">
                                <input
                                  type="checkbox"
                                  id="parentCheckbox"
                                  style={{ display: "none" }}
                                  name="wasteDisposal"
                                  class="form-check-input"
                                  checked={isWasteDisposalChecked}
                                  onChange={handleChange}
                                />
                                <label
                                  htmlFor="parentCheckbox"
                                  className="event-check"
                                >
                                  <div>
                                    <svg
                                      width="18px"
                                      height="18px"
                                      viewBox="0 0 18 18"
                                    >
                                      <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                      <polyline points="1 9 7 14 15 4"></polyline>
                                    </svg>
                                  </div>
                                  <div style={{ textWrap: "nowrap" }}>
                                    Waste Disposal
                                    <span className="tooltip-icon1">
                                      <i
                                        className="fa fa-info-circle"
                                        aria-hidden="true"
                                      />
                                      <span className="tooltip-text1">
                                        Would you like to track paper, plastic,
                                        and organic waste generated during the
                                        event? Standard default values will be
                                        provided.
                                      </span>
                                    </span>
                                  </div>
                                </label>
                              </div>

                              {isWasteDisposalChecked && (
                                <div className="child-container ml-4">
                                  <div className="sub-child-container">
                                    <input
                                      type="checkbox"
                                      id="Recycler"
                                      style={{ display: "none" }}
                                      name="Recycler"
                                      class="form-check-input"
                                      checked={
                                        formData.emissionBoundary.wasteDisposal
                                          .subitem.Recycler
                                      }
                                      onChange={
                                        handleWasteDisposalSubitemChange
                                      }
                                    />
                                    <label
                                      htmlFor="Recycler"
                                      className="event-check"
                                    >
                                      <div>
                                        <svg
                                          width="15px"
                                          height="15px"
                                          viewBox="0 0 18 18"
                                        >
                                          <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                          <polyline points="1 9 7 14 15 4"></polyline>
                                        </svg>
                                      </div>
                                      <div style={{ textWrap: "nowrap" }}>
                                        Recycler
                                      </div>
                                    </label>
                                  </div>
                                  <div className="sub-child-container">
                                    <input
                                      type="checkbox"
                                      style={{ display: "none" }}
                                      id="Landfill"
                                      name="Landfill"
                                      class="form-check-input"
                                      checked={
                                        formData.emissionBoundary.wasteDisposal
                                          .subitem.Landfill
                                      }
                                      onChange={
                                        handleWasteDisposalSubitemChange
                                      }
                                    />
                                    <label
                                      htmlFor="Landfill"
                                      className="event-check"
                                    >
                                      <div>
                                        <svg
                                          width="15px"
                                          height="15px"
                                          viewBox="0 0 18 18"
                                        >
                                          <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                          <polyline points="1 9 7 14 15 4"></polyline>
                                        </svg>
                                      </div>
                                      <div style={{ textWrap: "nowrap" }}>
                                        Landfill
                                      </div>
                                    </label>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 pt-3">
                      <div className="formdata">
                        <label>Water Provision</label>
                        <div className="radio-group">
                          <label className="radio-label">
                            <input
                              type="radio"
                              name="waterProvision"
                              className="form-check-input"
                              value="PackagedBottle"
                              id="PackagedBottle"
                              checked={
                                formData.waterProvision === "PackagedBottle"
                              }
                              onChange={handleChange}
                            />
                            <div class="radio-design"></div>
                            <div class="radio-text">Packaged Bottle</div>
                          </label>
                          <label className="radio-label">
                            <input
                              type="radio"
                              name="waterProvision"
                              id="FilledOnSite"
                              className="form-check-input"
                              value="FilledOnSite"
                              checked={
                                formData.waterProvision === "FilledOnSite"
                              }
                              onChange={handleChange}
                            />
                            <div class="radio-design"></div>
                            <div class="radio-text">Filled on-Site</div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-right">
                      <div className="form-buttons">
                        <button type="submit" className="btn save-button">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewForm;
