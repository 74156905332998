import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ShuttleTransport.css";
import "../src/assets/styles/bootstrap-4.4.1.css";
import "../src/assets/styles/style.css";
import { useEvent } from "./EventDetailsContext";
import EventName from "./EventName";
import CustomAlert from "./CustomAlert";
import { deleteShuttle, AddShuttle, getShuttleList } from "./apiService";

const ShuttleTransport = () => {
  const { eventDetails } = useEvent();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [datevalidation, setDateValidation] = useState(null);
  const [selectedShuttles, setSelectedShuttles] = useState([]);
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [shuttles, setShuttles] = useState([]);
  const [showCancel, setShowCancel] = useState(false);

  const ShuttleTransportModes = [
    { travelModeId: 16, type: "5-Seater", fuelType: "Diesel" },
    { travelModeId: 17, type: "5-Seater", fuelType: "Petrol" },
    { travelModeId: 18, type: "5-Seater", fuelType: "CNG" },
    { travelModeId: 19, type: "5-Seater", fuelType: "Electric" },
    { travelModeId: 20, type: "7-Seater", fuelType: "Diesel" },
    { travelModeId: 22, type: "7-Seater", fuelType: "CNG" },
    { travelModeId: 24, type: "Bus", fuelType: "Diesel" },
    { travelModeId: 26, type: "Bus", fuelType: "Electric" },
    { travelModeId: 27, type: "Traveller", fuelType: "Diesel" },
  ];
  const [selectedtype, setSelectedType] = useState(null);
  const [fueltype, setFuelType] = useState([]);

  const [formData, setFormData] = useState({
    vehicle: { type: "", fuel: "", distance: "", unit: "Km" },
  });

  const handleInputChange = (field, value) => {
    setFormData((prevformData) => ({
      ...prevformData,
      vehicle: {
        ...prevformData.vehicle,
        [field]: value,
      },
    }));
  };

  const handleCheckBoxChange = (shuttleId) => {
    setSelectedShuttles((prevSelectedShuttles) => {
      if (prevSelectedShuttles.includes(shuttleId)) {
        return prevSelectedShuttles.filter((id) => id !== shuttleId);
      } else {
        return [...prevSelectedShuttles, shuttleId];
      }
    });
  };

  const handleDeleteShuttles = () => {
    if (selectedShuttles.length === 0) {
      setAlertMessage("Please select a Shuttles to delete");
      setAlertType("error");
      setShowAlert(true);
      return;
    }
    setAlertMessage("Are you sure you want to delete the selected Shuttles?");
    setAlertType("warning");
    setShowAlert(true);
    setShowCancel(true);
    setConfirmationAction(() => confirmDelete);
  };

  const confirmDelete = async () => {
    try {
      console.log("Confirmation");
      await deleteShuttle(selectedShuttles);
      setSelectedShuttles([]);
      setAlertMessage("Selected Shuttles deleted successfully.");
      setAlertType("success");
      setSelectAll(false);
      fetchAndMapShuttles();
    } catch (error) {
      console.error("Error deleting Shuttles:", error);
      setAlertMessage("An error occurred while deleting Shuttles.");
      setAlertType("error");
    } finally {
      setShowAlert(true);
      setShowCancel(false);

      setConfirmationAction(null);
    }
  };

  const handleSelectAllChange = () => {
    setSelectAll((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;
      if (newSelectAll) {
        setSelectedShuttles(shuttles.map((shuttle) => shuttle.id));
      } else {
        setSelectedShuttles([]);
      }
      return newSelectAll;
    });
  };

  useEffect(() => {
    if (selectedShuttles.length === shuttles.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedShuttles, shuttles]);

  const mapShuttleTypeAndFuel = (shuttleData) => {
    return shuttleData.map((shuttle) => {
      const travelMode = ShuttleTransportModes.find(
        (mode) => mode.travelModeId === shuttle.travelMode
      );
      return {
        ...shuttle,
        type: travelMode ? travelMode.type : "Unknown",
        fuelType: travelMode ? travelMode.fuelType : "Unknown",
      };
    });
  };

  const fetchAndMapShuttles = async () => {
    try {
      const shuttleData = await getShuttleList(eventDetails.eventId);
      const mappedShuttles = mapShuttleTypeAndFuel(shuttleData);
      setShuttles(mappedShuttles);
    } catch (error) {
      console.error("Failed to fetch shuttle list:", error);
      setError("Failed to fetch shuttle list.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndMapShuttles();
  }, [eventDetails.eventId]);

  useEffect(() => {
    const fuelt = ShuttleTransportModes.filter(
      (shuttle) => shuttle.type === selectedtype
    ).map((type) => type.fuelType);
    setFuelType(fuelt);
  }, [selectedtype]);

  const getTravelModeId = (t, f) => {
    const travelMode = ShuttleTransportModes.find(
      (mode) => mode.type === t && mode.fuelType === f
    );
    return travelMode ? travelMode.travelModeId : null;
  };

  const convertMilesToKm = (miles) => {
    return parseFloat((miles * 1.60934).toFixed(2));
  };

  const handleAddShuttle = async (e) => {
    e.preventDefault();
    const { type, fuel, distance, unit } = formData.vehicle;
    let avgd;
    if (unit === "Miles") {
      avgd = convertMilesToKm(distance);
    } else {
      avgd = parseFloat(distance);
    }
    const travelM = getTravelModeId(selectedtype, fuel);

    if (!type || !fuel || !distance) {
      setAlertMessage("Please fill in all fields.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }

    const dataToSend = {
      EventId: eventDetails.eventId,
      AvgDistance: avgd,
      TravelMode: travelM,
    };

    try {
      await AddShuttle(dataToSend);
      setAlertMessage("Shuttle added successfully!");
      setAlertType("success");
      setShowAlert(true);
      fetchAndMapShuttles();
      // Reset form data
      setFormData({
        vehicle: { type: "", fuel: "", distance: "", unit: "Km" },
      });
      setSelectedType(null);
    } catch (error) {
      console.error("Failed to add shuttle:", error);
      setAlertMessage("Failed to add shuttle.");
      setAlertType("error");
      setShowAlert(true);
    }
  };
  const handleSaveAndNavigate = () => {
    if (
      eventDetails.boundary &&
      eventDetails.boundary.includes("Material Consumption")
    ) {
      navigate("/events/during-event-planning/material-consumption");
    } else {
      navigate("/events/post-event-planning/waste-generation");
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("success");
    setConfirmationAction(null);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <form
            className="shuttleForm mb-4"
            onSubmit={handleAddShuttle}
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          >
            <div className="row">
              <div className="col-md-4">
                <div className="form-group mb-4">
                  <div className="col-md-12">
                    <label htmlFor="typeVehicle">Type Of Vehicle</label>
                  </div>
                  <div className="col-md-12">
                    <select
                      id="typeVehicle"
                      value={formData.vehicle.type}
                      className="vehicle-dropdown"
                      onChange={(e) => {
                        setSelectedType(e.target.value);
                        handleInputChange("type", e.target.value);
                      }}
                    >
                      <option value="">Select Vehicle</option>
                      <option value="5-Seater">5-Seater</option>
                      <option value="7-Seater">7-Seater</option>
                      <option value="Traveller">Traveller</option>
                      <option value="Bus">Bus</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div className="col-md-12">
                    <label htmlFor="typeFuel">Type Of Fuel</label>
                  </div>
                  <div className="col-md-12">
                    <select
                      id="typeFuel"
                      value={formData.vehicle.fuel}
                      className="fuel-dropdown"
                      onChange={(e) =>
                        handleInputChange("fuel", e.target.value)
                      }
                    >
                      <option value="">Select Fuel</option>
                      {fueltype.map((fuel) => (
                        <option key={fuel} value={fuel}>
                          {fuel}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div className="col-md-12">
                    <label htmlFor="AvgDistance">
                      Average Distance Travelled
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        id="AvgDistance"
                        type="number"
                        value={formData.vehicle.distance}
                        min={0}
                        step={0.1}
                        onChange={(e) =>
                          handleInputChange(
                            "distance",
                            parseFloat(e.target.value)
                          )
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <select
                        value={formData.vehicle.unit}
                        onChange={(e) =>
                          handleInputChange("unit", e.target.value)
                        }
                      >
                        <option value="Km">Km</option>
                        <option value="Miles">Miles</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <button type="submit" className="btn GreenBtn mr-4">
                  Add Shuttle
                </button>
              </div>
            </div>
          </form>
          <hr />
          <div className="row p-2 pt-4">
            <div className="col-md-4 sort-delete-container">
              <span>
                <div className="deleteBtn-container">
                  <i
                    className="fa fa-trash"
                    aria-hidden="true"
                    onClick={handleDeleteShuttles}
                  ></i>
                  <span className="delete-tooltip">Delete</span>
                </div>
              </span>
            </div>
            <div className="col-md-8 text-right">
              <h3>Current shuttles in place</h3>
            </div>
          </div>

          <table className="table table-striped shuttle-table">
            <thead>
              <tr>
                <th className="text-center">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                </th>
                <th>Type Of Vehicle</th>
                <th>Type of Fuel</th>
                <th>Average Distance Travelled</th>
              </tr>
            </thead>
            <tbody>
              {shuttles.length > 0 ? (
                shuttles.map((shuttle) => (
                  <tr key={shuttle.id}>
                    <td align="center">
                      <input
                        type="checkbox"
                        checked={selectedShuttles.includes(shuttle.id)}
                        onChange={() => handleCheckBoxChange(shuttle.id)}
                      />
                    </td>
                    <td>{shuttle.type}</td>
                    <td>{shuttle.fuelType}</td>
                    <td>{shuttle.avgDistance}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="shuttle-empty-row">
                    No shuttles available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row mt-4">
            <div className="col-md-12 text-right">
              <button
                type="button"
                className="btn save-button"
                onClick={handleSaveAndNavigate}
              >
                Save
              </button>
            </div>
          </div>
          {showAlert && (
            <CustomAlert
              message={alertMessage}
              type={alertType}
              onClose={handleCloseAlert}
              onConfirm={confirmationAction}
              showCancel={showCancel}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShuttleTransport;
