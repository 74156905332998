import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useSearchParams } from "react-router-dom";
import {
  getEventConsumptionDetails,
  postEventConsumptionDetails,
} from "./apiService"; // Authorized API
//import { useEvent } from './EventDetailsContext';
import { useLocation } from "react-router-dom";
import EmissionPopup from "./EmissionPopup";
import Lottie from "lottie-react";
import car from "./assets/car.json";
import bus from "./assets/bus.json";
import train from "./assets/train.json";
import flight from "./assets/flight.json";
//import ecologo from "./assets/ecologo.png";
import Arrow from "./assets/Arrow.png";
import mealll from "./assets/mealll.png";
import nonVeg from "./assets/nonVeg.png";
import Vegan from "./assets/vegan.png";
import hline from "./assets/hline.png";
import "./MyData.css";
import ReactSpeedometer from "react-d3-speedometer";

const mealPreferences = { Veg: 6, "Non-Veg": 7, Vegan: 8 };

const travelModes = {
  "Car-Diesel": 1,
  "Car-Petrol": 2,
  "Car-CNG": 3,
  "Car-Electric": 4,
  "Bus-Fuel-Based": 5,
  "Train-Train": 6,
  "Bus-Electric": 7,
  "Flight-Economy": 8,
  "Flight-Business": 13,
};

const getIds = (travelModeId) => {
  const travelModes = {
    1: { travelMode: "Car", fuelMode: "Diesel" },
    2: { travelMode: "Car", fuelMode: "Petrol" },
    3: { travelMode: "Car", fuelMode: "CNG" },
    4: { travelMode: "Car", fuelMode: "Electric" },
    5: { travelMode: "Bus", fuelMode: "Fuel-Based" },
    6: { travelMode: "Train", fuelMode: "Train" },
    7: { travelMode: "Bus", fuelMode: "Electric" },
    8: { travelMode: "Flight", fuelMode: "Economy" },
    13: { travelMode: "Flight", fuelMode: "Business" },
  };

  const modeDetails = travelModes[travelModeId] || {};

  return {
    travelMode: modeDetails.travelMode || "",
    fuelMode: modeDetails.fuelMode || "",
    travelModeId,
  };
};

function MyData(eventDetails) {
  //const locationState = useLocation();
  const location =
    eventDetails.eventDetails.location === null
      ? null
      : eventDetails.eventDetails.location;
  const startDate =
    eventDetails.eventDetails.startDate === null
      ? null
      : eventDetails.eventDetails.startDate;
  const endDate =
    eventDetails.eventDetails.endDate === null
      ? null
      : eventDetails.eventDetails.endDate;
  const eventName =
    eventDetails.eventDetails.eventName === null
      ? null
      : eventDetails.eventDetails.eventName;
  //
  //const totalEmission = eventDetails.eventDetails.totalEmission===null ? null : eventDetails.eventDetails.totalEmission;

  //console.log(location, eventDetails.eventDetails.location);
  //const locationState = useLocation();
  //const { location } = locationState.state || {}
  //const location = localStorage.getItem('location');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // const handleMealChange = (e) => {
  //   const { value } = e.target;
  //   setMealMode(value);
  // };
  const mealProvided = eventDetails?.eventDetails?.mealProvided;
  const mealType = eventDetails.eventDetails.mealType;
  console.log("Meal Provided:", mealProvided);
  const [eventId, setEventId] = useState(searchParams.get("id"));

  //54r const [eventId, setEventId] = useState(searchParams.get("id"));
  //const [eventDetails, setEventDetails] = useState({});
  //const [eventName, setEventName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [travelMode, setTravelMode] = useState("Car");
  const [fuelOptions, setFuelOptions] = useState([
    "Petrol",
    "Diesel",
    "Electric",
    "CNG",
  ]);
  const [FuelMode, setFuelMode] = useState("");
  const [MealMode, setMealMode] = useState("");
  // const [endLocation, setEndLocation] = useState("");

  const [stayDays, setStayDays] = useState(1);
  const [startingLocation, setStartingLocation] = useState("");
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  //const [mealProvided, setMealProvided] = useState("");
  const [formData, setFormData] = useState({
    eventDuration: "0",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      let newFormData = { ...prevState, [name]: value };

      if (name === "eventDuration") {
        newFormData.eventDuration = value;
      }

      return newFormData;
    });
  };

  //const [startDate, setStartDate] = useState("");
  //const [endDate, setEndDate] = useState("");
  const locationRef = useRef(null);
  const setLocation = (location) => {
    // Your logic to set the location
    console.log("Location set to:", location);
  };
  //const[location,setLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [error, setError] = useState(null);
  const [totalEmission, setTotalEmission] = useState();
  const [travelModeId, setTravelModeId] = useState("");

  // Hard-coded accommodationId
  const accommodationId = 3;

  useEffect(() => {
    if (mealProvided) {
      setMealMode("Veg"); // Default to "Veg" if mealProvided is true
    } else {
      setMealMode(""); // No default if mealProvided is false
    }
  }, [mealProvided]);

  const handleMealChange = (event) => {
    setMealMode(event.target.value);
  };

  useEffect(() => {
    if (eventDetails.eventDetails.eventId) {
      const fetchEventDetails = async () => {
        try {
          const data = await getEventConsumptionDetails(
            eventDetails.eventDetails.eventId
          );
          console.log("Fetched Event consumption details:", data);

          const fetchedMealPreferenceId = data.mealPreferenceId;
          const fetchedMealMode =
            Object.keys(mealPreferences).find(
              (key) => mealPreferences[key] === fetchedMealPreferenceId
            ) || (eventDetails.mealProvided ? "Veg" : "");

          setMealMode(fetchedMealMode);
          setStayDays(data.days || 1);
          setStartingLocation(data.location || "");
          setFormData((prev) => ({
            ...prev,
            eventDuration: data.days || "",
          }));
          setPlaceSelected(true);
          // Update this section
          const { travelMode, fuelMode } = getIds(data.travelModeId);
          setTravelMode(travelMode);
          setFuelMode(fuelMode);
          setTravelModeId(data.travelModeId);

          // Update fuel options based on travel mode
          updateFuelOptions(travelMode);

          setTotalEmission(data.totalEmissions?.toFixed(2) || 0);
        } catch (error) {
          setError("Failed to fetch event details");
        } finally {
          setLoading(false);
        }
      };
      fetchEventDetails();
    } else {
      setError("Event ID is missing");
      setLoading(false);
    }
  }, [eventDetails.eventDetails.eventId]);

  // Add this new function
  const updateFuelOptions = (mode) => {
    switch (mode) {
      case "Bus":
        setFuelOptions(["Fuel-Based", "Electric"]);
        break;
      case "Train":
        setFuelOptions(["Train"]);
        break;
      case "Flight":
        setFuelOptions(["Economy", "Business"]);
        break;
      default:
        setFuelOptions(["Petrol", "Diesel", "Electric", "CNG"]);
    }
  };

  const handleTravelChange = (event) => {
    const selectedMode = event.target.value;
    setTravelMode(selectedMode);
    updateFuelOptions(selectedMode);

    if (selectedMode === "Car") {
      setFuelMode("Petrol");
    } else if (selectedMode === "Bus") {
      setFuelMode("Fuel-Based");
    } else if (selectedMode === "Train") {
      setFuelMode("Train");
    } else if (selectedMode === "Flight") {
      setFuelMode("Economy");
    } else {
      setFuelMode("");
    }
  };

  const calculateDaysDifference = (start, end) => {
    const startDay = new Date(start);
    const endDay = new Date(end);

    // Calculate the difference in milliseconds
    const difference = endDay - startDay;

    // Convert milliseconds to days
    return Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24)));
  };
  const handleDaysChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (startDate && endDate) {
      // Calculate the number of days between startDate and endDate
      const days = calculateDaysDifference(startDate, endDate);

      // Create a mapping for the event duration
      const newDurationMapping = {};
      for (let i = 0; i <= days + 1; i++) {
        newDurationMapping[i] = i;
      }
      setEventDurationMapping(newDurationMapping);
    }
  }, [startDate, endDate]);

  const [eventDurationMapping, setEventDurationMapping] = useState({ 0: 0 });
  const [placeSelected, setPlaceSelected] = useState(false);

  console.log("event details :", eventDetails);
  useEffect(() => {
    if (eventDetails.startDate && eventDetails.endDate) {
      const start = new Date(eventDetails.startDate).getDate();
      const end = new Date(eventDetails.endDate).getDate();
      setMinValue(start);
      setMaxValue(end);
    }
  }, [eventDetails.startDate, eventDetails.endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate).getDate();
      const end = new Date(endDate).getDate();
      setMinValue(start);
      setMaxValue(end);
    }
  }, [startDate, endDate]);

  const handleFuelChange = (event) => {
    setFuelMode(event.target.value);
  };

  useEffect(() => {
    const initializeAutocomplete = () => {
      const locationInput = locationRef.current;

      const autocomplete = new window.google.maps.places.Autocomplete(
        locationInput,
        {
          strictBounds: false,
        }
      );

      autocomplete.setFields([
        "formatted_address",
        "geometry.location",
        "name",
        "place_id",
      ]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          setPlaceSelected(false); // Set placeSelected to false
          return;
        }

        setPlaceSelected(true);
        const selectedText = locationInput.value;
        setStartingLocation(selectedText);
        setLocation(selectedText);
        console.log("Selected place:", place.formatted_address);
      });
    };

    if (window.google && window.google.maps) {
      initializeAutocomplete();
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCpdevcXjKt9CVD1n8chB59MGW0d1uT2dg&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initializeAutocomplete;
      document.head.appendChild(script);
    }
  }, []);
  console.log("place selected", placeSelected);

  const handleSubmit = async () => {
    // Validation

    const travelModeKey = `${travelMode}-${FuelMode}`;
    const travelModeId = travelModes[travelModeKey];

    const staying = Number(formData.eventDuration);

    // Get the mealPreferenceId
    const mealPreferenceId = mealPreferences[MealMode];

    if (!placeSelected) {
      setAlertMessage(
        "All fields must be filled, and location must be selected from suggestions."
      );
      setAlertType("error");
      setShowAlert(true);
      return;
    }

    if (!travelModeId) {
      setAlertMessage(
        "Please select valid travel mode and fuel mode combination"
      );
      setAlertType("error");
      setShowAlert(true);
      return;
    }

    if (!stayDays || !startingLocation || !accommodationId) {
      setAlertMessage("All fields must be filled");
      setAlertType("error");
      setShowAlert(true);
      return;
    }

    // Conditionally set mealPreferenceId based on mealProvided
    const finalMealPreferenceId = mealProvided ? mealPreferenceId : 9;
    //Accomodation to NA if stay nights is zero
    const accom = staying === 0 ? 4 : accommodationId;
    if (!mealProvided) {
      if (!formData.eventDuration || isNaN(Number(formData.eventDuration))) {
        setAlertMessage("Please enter a valid number for Stay Days");
        setAlertType("error");
        setShowAlert(true);
        return;
      }
    }

    const data = {
      AccommodationId: accom,
      EventId: eventDetails.eventDetails.eventId,
      MealPreferenceId: finalMealPreferenceId,
      StayDays: formData.eventDuration || null,
      TravelModeId: travelModeId,
      UserEmail: email,
      Location: {
        Location: startingLocation,
        TransportationType: travelModeId,
      },
    };

    try {
      const response = await postEventConsumptionDetails(data); // Using authorized API
      console.log("Data sent:", data);
      console.log("Data saved successfully:", response);

      const emissionValue = response.totalCarbonEmission?.result || 0;
      const roundedEmission = parseFloat(emissionValue).toFixed(2);
      console.log("Rounded Total Emission:", roundedEmission);

      setTotalEmission(roundedEmission);
      setAlertMessage("Data saved successfully");
      setAlertType("success");
      setShowAlert(true);
    } catch (error) {
      console.error("Error saving data:", error);
      setAlertMessage(
        "Error saving data. Please check your inputs and try again."
      );
      setAlertType("error");
      setShowAlert(true);
    }
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    console.log("Event role:", eventDetails.eventDetails.myRole);

    // Check if alertType is "success" and myRole is "Event Manager" before navigating
    if (
      alertType === "success" &&
      eventDetails.eventDetails.myRole === "Event Manager"
    ) {
      navigate("/events/Organizer/Organizer-info");
    } else {
      console.log(
        "Navigation skipped as the alert type is not success or the user is not an Event Manager."
      );
    }
  };

  const getAnimation = () => {
    switch (travelMode) {
      case "Bus":
        return <Lottie animationData={bus} className="bus-my" />;
      case "Train":
        return <Lottie animationData={train} className="train-my" />;
      case "Flight":
        return <Lottie animationData={flight} className="flight-my" />;
      default:
        return <Lottie animationData={car} className="car-my" />;
    }
  };

  useEffect(() => {
    const calculatedStayDays = maxValue - minValue + 1;
    setStayDays(calculatedStayDays);
  }, [minValue, maxValue]);

  const extractCityStateCountry = (address) => {
    if (!address) return "N/A";
    const parts = address.split(",").slice(-3);
    return parts.join(", ").trim().replace(/[0-9]/g, "");
  };

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmallScreen = dimensions.width < 768;
  const isVerySmallScreen = dimensions.width < 480;

  return (
    <div className="containers-add">
      <div className="forms-section">
        {/* <img src={ecologo} alt="" /> */}
        <div className="questions">How will you travel to the Event?</div>
        <div className="radios-group-my">
          {["Car", "Bus", "Train", "Flight"].map((mode) => (
            <div key={mode} style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                id={mode.toLowerCase()}
                required
                style={{ display: "none" }}
                name="travel"
                value={mode}
                checked={travelMode === mode}
                onChange={handleTravelChange}
              />
              <label
                htmlFor={mode.toLowerCase()}
                className="check"
                style={{ marginRight: "8px" }}
              >
                <svg width="18px" height="18px" viewBox="0 0 18 18">
                  <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                  <polyline points="1 9 7 14 15 4"></polyline>
                </svg>
              </label>
              <span>{mode}</span>
            </div>
          ))}
        </div>

        <div className="questions">Type of Transportation:</div>
        <div className="radios-group1-my">
          {fuelOptions.map((option) => {
            const optionId = option.replace(/\s+/g, "-").toLowerCase();

            return (
              <div
                key={optionId}
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  type="checkbox"
                  id={optionId}
                  style={{ display: "none" }}
                  name="fuel"
                  value={option}
                  checked={FuelMode === option}
                  onChange={handleFuelChange}
                  required
                />
                <label
                  htmlFor={optionId}
                  className={`check ${optionId}`}
                  style={{ marginRight: "8px" }}
                >
                  <svg width="18px" height="18px" viewBox="0 0 18 18">
                    <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                    <polyline points="1 9 7 14 15 4"></polyline>
                  </svg>
                </label>
                <span>{option}</span>
              </div>
            );
          })}
        </div>

        <div className="questions">Location:</div>

        <div className="questions-my">
          <span className="locations-icon">
            {" "}
            <i class="fa fa-map-marker" style={{ color: "#D90166" }}></i>
          </span>
          <input
            className="inpuut"
            placeholder="Location"
            ref={locationRef}
            value={startingLocation}
            required
            onChange={(e) => {
              setStartingLocation(e.target.value);
              setPlaceSelected(false);
            }}
          />
        </div>

        <div className="questions-my-duration">Duration of stay in hotel:</div>

        <div className="duration-container-my">
          <div className="dropdown-wrapper-my">
            <select
              id="eventDuration"
              name="eventDuration"
              value={formData.eventDuration}
              onChange={handleChange}
              required
            >
              {Object.values(eventDurationMapping).map((duration) => (
                <option key={duration} value={duration}>
                  {duration}
                </option>
              ))}
            </select>
            <span className="night-text">
              Night{formData.eventDuration > 1 ? "s" : ""}
            </span>
          </div>
        </div>
        {mealProvided && (
          <>
            <div className="questions">Meal Preference:</div>
            <div className="radios-group2-my">
              {/* Define the meal options based on mealType */}
              {["Veg", "Non-Veg", "Vegan"]
                .filter((meal) => {
                  // Only show the options based on mealType
                  if (mealType === "Veg") {
                    return meal === "Veg";
                  } else if (mealType === "Veg|Alcohol") {
                    return meal === "Veg";
                  } else if (mealType === "NonVeg") {
                    return meal === "Non-Veg";
                  } else if (mealType === "NonVeg|Alcohol") {
                    return meal === "Non-Veg";
                  } else if (mealType === "Vegan") {
                    return meal === "Vegan";
                  } else if (mealType === "Vegan|Alcohol") {
                    return meal === "Vegan";
                  } else if (mealType === "Veg|NonVeg") {
                    return meal === "Veg" || meal === "Non-Veg";
                  } else if (mealType === "Veg|NonVeg|Alcohol") {
                    return meal === "Veg" || meal === "Non-Veg";
                  } else if (mealType === "Veg|Vegan|Alcohol") {
                    return meal === "Veg" || meal === "Vegan";
                  } else if (mealType === "Veg|Vegan") {
                    return meal === "Veg" || meal === "Vegan";
                  } else if (mealType === "NonVeg|Vegan") {
                    return meal === "Non-Veg" || meal === "Vegan";
                  } else if (mealType === "NonVeg|Vegan|Alcohol") {
                    return meal === "Non-Veg" || meal === "Vegan";
                  } else if (mealType === "Veg|NonVeg|Vegan") {
                    return true; // Show all options
                  } else if (mealType === "Veg|NonVeg|Vegan|Alcohol") {
                    return true; // Show all options
                  }
                  return false; // Default case if none match
                })
                .map((meal) => (
                  <div
                    key={meal}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      id={meal.toLowerCase().replace(/\s+/g, "-")}
                      style={{ display: "none" }}
                      name="Meal"
                      value={meal}
                      checked={MealMode === meal}
                      onChange={handleMealChange}
                      required
                    />
                    <label
                      htmlFor={meal.toLowerCase().replace(/\s+/g, "-")}
                      className="check"
                      style={{ marginRight: "8px" }}
                    >
                      <svg width="18px" height="18px" viewBox="0 0 18 18">
                        <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                        <polyline points="1 9 7 14 15 4"></polyline>
                      </svg>
                    </label>
                    <span>{meal}</span>
                  </div>
                ))}
            </div>
          </>
        )}

        <button onClick={handleSubmit} className="summary-button">
          Submit
        </button>
      </div>

      <div className="details-section-my">
        <h3>
          <span>Travel</span> <br /> Details
        </h3>

        <div className="car-image-my">{getAnimation()}</div>

        <div className="journey-details-my">
          <div className="journey-line-my">
            <span className="start-journey-my">
              <i class="fa fa-map-marker" style={{ color: "#D90166" }}></i> {""}
              {extractCityStateCountry(startingLocation)}
            </span>

            <img src={Arrow} alt="" />

            <span>
              <span className="end-journey-my">
                <i className="fa fa-map-marker" style={{ color: "green" }}></i>{" "}
                {""}
                {extractCityStateCountry(location)}
              </span>
            </span>
          </div>

          <div className="date-meal-my">
            <div className="date-meal-details-my">
              <div className="date-box-my">
                <div className="start-date-box-my">
                  <span className="label-my">START DATE</span>
                  <div className="date-my">
                    <span className="day-my">
                      {startDate ? new Date(startDate).getDate() : "14"}
                    </span>
                    <div className="month-year-my">
                      <span className="month-my">
                        {startDate
                          ? new Date(startDate).toLocaleString("default", {
                              month: "short",
                            })
                          : "Sep"}
                      </span>
                      <span className="year-my">
                        {startDate ? new Date(startDate).getFullYear() : "24"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="end-date-box-my">
                  <span className="label-my">END DATE</span>
                  <div className="date-my">
                    <span className="day-my">
                      {endDate ? new Date(endDate).getDate() : "16"}
                    </span>
                    <div className="month-year-my">
                      <span className="month-my">
                        {endDate
                          ? new Date(endDate).toLocaleString("default", {
                              month: "short",
                            })
                          : "Sep"}
                      </span>
                      <span className="year-my">
                        {endDate ? new Date(endDate).getFullYear() : "24"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="meal-type-my">
              {mealProvided && MealMode && (
                <div className="meal-type">
                  {MealMode === "Veg" && (
                    <img
                      src={mealll}
                      alt="Veg Meal"
                      style={{
                        width: window.innerWidth < 768 ? "40px" : "55px",
                        height: "auto",
                      }}
                    />
                  )}

                  {MealMode === "Non-Veg" && (
                    <img
                      src={nonVeg}
                      alt="Non-Veg Meal"
                      style={{
                        width: window.innerWidth < 768 ? "40px" : "55px",
                        height: "auto",
                      }}
                    />
                  )}

                  {MealMode === "Vegan" && (
                    <img
                      src={Vegan}
                      alt="Vegan Meal"
                      style={{
                        width: window.innerWidth < 768 ? "40px" : "55px",
                        height: "auto",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="emission-section-my">
          <div className="meter-my">
            <ReactSpeedometer
              width={isSmallScreen ? 160 : 230}
              height={isVerySmallScreen ? 160 : 230}
              needleTransitionDuration={4000}
              needleColor="black"
              value={Math.min(totalEmission || 0, 240)}
              minValue={0}
              maxValue={240}
              segments={3}
              segmentColors={["#4dbce8", "#ffa342", "#f00018"]}
              customSegmentStops={[0, 80, 160, 240]}
              forceRender={true}
              labelFontSize={"0px"}
              ringWidth={isSmallScreen ? 20 : 40}
              valueTextFontSize={"0px"}
            />
          </div>
          <div className="data-list-my">
            <div className="emission-meter-my">🍃 TOTAL EMISSION</div>
            <div
              className="emission-value-my"
              style={{
                color:
                  totalEmission >= 160
                    ? "#f00018"
                    : totalEmission >= 80
                    ? "#ffa342"
                    : "#4dbce8",
              }}
            >
              {totalEmission ? `${totalEmission} Kg CO2` : "Calculating..."}
            </div>
            <div
              className="emission-level-my"
              style={{
                color:
                  totalEmission >= 160
                    ? "#f00018"
                    : totalEmission >= 80
                    ? "#ffa342"
                    : "#4dbce8",
              }}
            >
              {totalEmission >= 160
                ? "Oops! You exceeded the carbon emissions limit."
                : totalEmission >= 80
                ? "You are within the carbon emissions limit."
                : "Great! You are below the carbon emission limit."}
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <EmissionPopup
          message={alertMessage}
          onClose={handleCloseAlert}
          onConfirm={handleCloseAlert}
          totalEmission={totalEmission}
        />
      )}
    </div>
  );
}

export default MyData;
