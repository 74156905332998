import React, { useEffect, useRef } from "react";
import "./CustomAlertStyles.css";

const CustomAlert = ({ message, onClose, onConfirm, showCancel = false }) => {
  const modalRef = useRef(null);

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    onClose();
  };
  console.log(
    "Message:",
    message,
    "onClose:",
    onClose,
    "onConfirm:",
    onConfirm,
    "showCancel",
    showCancel
  );
  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.focus();
    }
    // Prevent scrolling when modal is open
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="alert-overlay">
      <div
        className="alert-modal slit-in-vertical"
        role="dialog"
        aria-modal="true"
        aria-labelledby="alert-title"
        aria-describedby="alert-description"
        tabIndex="-1"
        ref={modalRef}
      >
        <div className="alert-content">
          <div className="alert-header">
            <h5 className="alert-title" id="alert-title">
              Alert
            </h5>
            <button
              type="button"
              className="alert-close-button"
              onClick={onClose}
              aria-label="Close"
            >
              &times;
            </button>
          </div>
          <div className="alert-body" id="alert-description">
            <p>{message}</p>
          </div>
          <div className="alert-footer">
            {showCancel && (
              <button
                type="button"
                className="alert-cancel-button"
                onClick={onClose}
              >
                Cancel
              </button>
            )}
            <button
              type="button"
              className="alert-confirm-button"
              onClick={handleConfirm}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
