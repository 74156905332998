import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./DoughnutChart.css";
import { getEmissionDuringEventActivity, useApi } from "./apiService";

const DoughnutChart2 = React.memo(
  ({ eventId, totalEventEmission, onHandleChart2Click }) => {
    const chartRef = useRef(null);
    const canvasRef = useRef(null);
    const [mainData, setMainData] = useState([]);
    const [detailedData, setDetailedData] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [hasPositiveData, setHasPositiveData] = useState(false);
    const [totalEmission, setTotalEmission] = useState(0);
    const api = useApi();

    const colorPalette = [
      "#a18cd1",
      "#ff9a9e",
      "#8fd3f4",
      "#a1c4fd",
      "#8de9d5",
    ];

    const hasAnyPositiveValue = (values) => values.some((value) => value > 0);

    useEffect(() => {
      const fetchData = async () => {
        try {
          console.log("Pass eventId:", eventId);
          const data = await getEmissionDuringEventActivity(eventId);
          console.log("DuringEventEmission Data:", data);
          setMainData(data.mainData);
          setDetailedData(data.detailedData);
          setIsDataFetched(true);

          const dataValues = data.mainData.map((item) => item.cumulativevalue);
          setHasPositiveData(hasAnyPositiveValue(dataValues));

          const total = dataValues.reduce((acc, value) => acc + value, 0);
          setTotalEmission(total);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsDataFetched(true);
          setHasPositiveData(false);
        }
      };

      fetchData();
    }, [eventId]);

    useEffect(() => {
      if (
        !hasPositiveData ||
        mainData.length === 0 ||
        detailedData.length === 0
      )
        return;

      const labels = mainData.map((item) =>
        item.emissionType === "Shuttle Service"
          ? "Shuttle/Local Transport"
          : item.emissionType
      );
      const dataValues = mainData.map((item) => item.cumulativevalue);

      const data = {
        labels,
        datasets: [
          {
            label: "Carbon Emission",
            data: dataValues,
            backgroundColor: colorPalette,
            borderWidth: 3,
            borderRadius: 8,
            cutout: "85%",
            borderColor: "#e4e8f6",
            hoverBackgroundColor: colorPalette,
            hoverBorderWidth: 3,
            hoverBorderColor: "#e4e8f6",
            hoverOffset: 6,
            spacing: 3,
          },
        ],
      };

      // Updated this part to add "KgCO₂" as suffix directly
      const detailedDatasets = detailedData.map((item, index) => ({
        labels: item.data.map((sub) => sub.emissionSubcategory),
        data: item.data.map((sub) => Number(Number(sub.value).toFixed(2))),
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      }));

      const ctx = canvasRef.current.getContext("2d");
      if (chartRef.current) {
        chartRef.current.destroy();
      }
      chartRef.current = new Chart(ctx, {
        type: "doughnut",
        data,
        options: {
          onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0]
              ? "pointer"
              : "default";
          },
          plugins: {
            tooltip: {
              usePointStyle: true,
              callbacks: {
                label: function (context) {
                  // Add "KgCO₂" suffix and format to two decimal places
                  let label = context.label || "";
                  if (context.parsed) {
                    const formattedValue = context.parsed.toFixed(2); // Format value to two decimal places
                    label += `: ${formattedValue} KgCO₂`;
                  }
                  return label;
                },
              },
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              titleColor: "#ffffff",
              bodyColor: "#ffffff",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 0,
            },
            legend: {
              display: true,
              position: "bottom",
              labels: {
                generateLabels: (chart) => {
                  const datasets = chart.data.datasets;
                  return datasets[0].data.map((data, i) => ({
                    text: ` ${chart.data.labels[i]} : ${Number(
                      data.toFixed(2)
                    ).toLocaleString()} KgCO₂ (${(
                      (data / totalEmission) *
                      100
                    ).toFixed(2)}%)`,
                    fillStyle: datasets[0].backgroundColor[i],
                    index: i,
                  }));
                },
                usePointStyle: true,
                pointStyle: "circle",
                padding: 12,
                font: {
                  size: 10,
                },
              },
              align: "start",
            },
            datalabels: {
              display: false,
            },
          },
        },
        plugins: [
          {
            id: "textCenter",
            beforeDatasetsDraw(chart) {
              const { ctx, data } = chart;
              ctx.save();
              ctx.font = "12px Rubick, sans-serif";
              ctx.fillStyle = "#2e688dbb";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText(
                `TOTAL`,
                chart.getDatasetMeta(0).data[0].x,
                chart.getDatasetMeta(0).data[0].y - 20
              );
              ctx.font = "16px Rubik, Lado, sans-serif";
              ctx.fillStyle = "#2e688d";
              ctx.fillText(
                `${totalEmission.toLocaleString()} KgCO₂`,
                chart.getDatasetMeta(0).data[0].x,
                chart.getDatasetMeta(0).data[0].y
              );
              ctx.fillStyle = "#2e688dbb";
              ctx.font = "14px Rubick, sans-serif";
              ctx.fillText(
                `(${((totalEmission / totalEventEmission) * 100)
                  .toFixed(2)
                  .toLocaleString()}%)`,
                chart.getDatasetMeta(0).data[0].x,
                chart.getDatasetMeta(0).data[0].y + 20
              );
            },
            afterEvent(chart, args) {
              const event = args.event;
              if (event.type === "click") {
                const elements = chart.getElementsAtEventForMode(
                  event,
                  "nearest",
                  { intersect: true },
                  false
                );
                if (elements.length) {
                  const index = elements[0].index;

                  if (index < detailedDatasets.length) {
                    const detailedDataset = detailedDatasets[index];
                    if (chart.data.datasets.length === 1) {
                      onHandleChart2Click({
                        name: chart.data.labels[index],
                        label: detailedDataset.labels,
                        data: detailedDataset.data,
                      });
                    }
                  }
                }
              }
            },
          },
        ],

        // plugins: [
        //   {
        //     beforeEvent(chart, args) {
        //       const event = args.event;
        //       const chartInfo = document.getElementById('chartInfo');
        //       const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
        //       let infoText = '';

        //       if (event.type === 'mousemove' && !isDetailChartVisible) {
        //         if (elements.length) {
        //           const index = elements[0].index;

        //           if (index < detailedDatasets.length && chart.data.datasets.length === 1) {
        //             const detailedDataset = detailedDatasets[index];
        //             chart.data.datasets.push({
        //               label: chart.data.labels[index],
        //               data: detailedDataset.data,
        //               backgroundColor: detailedDataset.backgroundColor,
        //               borderColor: detailedDataset.borderColor,
        //               borderWidth: detailedDataset.borderWidth,
        //             });
        //             chart.options.cutout = '30%';
        //             chart.update();
        //             infoText = `<p class="info-title" style="color: ${chart.data.datasets[0].backgroundColor[index]};">${chart.data.labels[index]}</p><ul class="info-list">`;
        //             detailedDataset.labels.forEach((label, i) => {
        //               infoText += `<li><span class="color-indicator" style="background-color: ${detailedDataset.backgroundColor[i]};"></span>${label}</li>`;
        //             });
        //             infoText += '</ul>';
        //             chartInfo.innerHTML = infoText;
        //           }
        //         } else if (chart.data.datasets.length > 1 && !isDetailChartVisible) {
        //           chart.data.datasets.pop();
        //           chart.options.cutout = '70%';
        //           chart.update();
        //           chartInfo.innerHTML = ''; // Clear infoText to prevent duplication
        //         }
        //       }
        //     },
        //     afterEvent(chart, args) {
        //       const event = args.event;
        //       const chartInfo = document.getElementById('chartInfo');
        //       if (event.type === 'click') {
        //         const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
        //         if (elements.length) {
        //           const index = elements[0].index;
        //           setClickedIndex(index);
        //           setIsDetailChartVisible(true);

        //           if (index < detailedDatasets.length) {
        //             const detailedDataset = detailedDatasets[index];
        //             if (chart.data.datasets.length === 1) {
        //               chart.data.datasets.push({
        //                 label: chart.data.labels[index],
        //                 data: detailedDataset.data,
        //                 backgroundColor: detailedDataset.backgroundColor,
        //                 borderColor: detailedDataset.borderColor,
        //                 borderWidth: detailedDataset.borderWidth,
        //               });
        //             } else {
        //               chart.data.datasets[1].data = detailedDataset.data;
        //               chart.data.datasets[1].backgroundColor = detailedDataset.backgroundColor;
        //               chart.data.datasets[1].borderColor = detailedDataset.borderColor;
        //               chart.data.datasets[1].borderWidth = detailedDataset.borderWidth;
        //             }
        //             chart.options.cutout = '20%';
        //             chart.update();
        //             let infoText = `<p class="info-title" style="color: ${chart.data.datasets[0].backgroundColor[index]};">${chart.data.labels[index]}</p><ul class="info-list">`;
        //             detailedDataset.labels.forEach((label, i) => {
        //               infoText += `<li><span class="color-indicator" style="background-color: ${detailedDataset.backgroundColor[i]};"></span>${label}</li>`;
        //             });
        //             infoText += '</ul>';
        //             chartInfo.innerHTML = infoText;
        //           }
        //         }
        //       }
        //     },
        //   },
        //   ChartDataLabels,
        // ],
      });

      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }, [mainData, detailedData, hasPositiveData, totalEventEmission]);

    return (
      <div className="chart-container">
        <div className="chart-header">
          <h3>Emissions of During Event Activities</h3>
        </div>
        <div className="chart-data">
          {isDataFetched && hasPositiveData ? (
            <canvas ref={canvasRef} id="myChart2" />
          ) : (
            <div style={{ textAlign: "center" }}>
              <br /> <br /> <br /> <br /> <br />
              <h5>Data Awaited</h5>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default DoughnutChart2;
