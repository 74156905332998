import React, { useState, useEffect, useRef } from "react";
import "./eventDetailsSidebar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Attendee from "./assets/Attendee.png";
import Energy from "./assets/Energy.png";
import Meal from "./assets/Meal.png";
import Material from "./assets/Material.png";
import Mydata from "./assets/Mydata.png";
import Waste from "./assets/Waste.png";
import Preplan from "./assets/Preplan.png";

const EventDetailsSidebar = ({ eventDetails, isCollapsed }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("");
  const [activeSubSection, setActiveSubSection] = useState("");
  const underlines = useRef();
  console.log(eventDetails);

  useEffect(() => {
    const path = location.pathname;
  
    if (path.includes("/events/Organizer")) {
      setActiveButton("Organizer");
      const subSection = path.split("/Organizer/")[1] || "Organizer-info";
      setActiveSubSection(subSection);
      if (!path.includes(subSection)) {
        navigate("/events/Organizer/Organizer-info", { state: { eventDetails } });
      }
    } else if (path.includes("/during-event-planning")) {
      setActiveButton("during-event-planning");
      let subSection = path.split("/during-event-planning/")[1] || "attendee-details";

      setActiveSubSection(subSection);
      if (!path.includes(subSection)) {
        navigate(`/events/during-event-planning/${subSection}`, { state: { eventDetails } });
      }
    } else if (path.includes("/post-event-planning")) {
      setActiveButton("post-event-planning");
      const subSection = path.split("/post-event-planning/")[1] || "waste-generation";
      setActiveSubSection(subSection);
      if (!path.includes(subSection)) {
        navigate("/events/post-event-planning/waste-generation", { state: { eventDetails } });
      }
    } else if (path.includes("/pre-event-planning")) {
      setActiveButton("pre-event-planning");
      const subSection = path.split("/pre-event-planning/")[1] || "pre-planning";
      setActiveSubSection(subSection);
      if (!path.includes(subSection)) {
        navigate("/events/pre-event-planning/pre-planning", { state: { eventDetails } });
      }
    } else if (path.includes("/individual")) {
      setActiveButton("individual");
      const subSection = path.split("/individual/")[1] || "my-data";
      setActiveSubSection(subSection);
      if (!path.includes(subSection)) {
        navigate("/events/individual/my-data", { state: { eventDetails } });
      }
    } else if (path.includes("/events/report")) {
      setActiveButton("report");
      const subSection = path.split("/report")[1] || "summary-report";
      setActiveSubSection(subSection);
      if (!path.includes(subSection)) {
        navigate("/events/report", { state: { eventDetails } });
      }
    } else if (path.includes("/event-details")) {
      setActiveButton("Event Details");
      setActiveSubSection("");
    } else {
      setActiveButton("during-event-planning");
      setActiveSubSection("attendee-details");
      navigate("/events/during-event-planning/attendee-details", { state: { eventDetails } });
    }
  }, [location.pathname, navigate, eventDetails]);
  

  const handleButtonClick = (section, defaultSubSection) => {
    setActiveButton(section); // Always set the active button to the clicked section
    setActiveSubSection(defaultSubSection);
    
    // Navigate based on section
    if (section === "pre-event-planning") {
      navigate(`/events/pre-event-planning/${defaultSubSection}`, {
        state: { eventDetails },
      });
    } else if (section === "during-event-planning") {
      navigate(`/events/during-event-planning/${defaultSubSection}`, {
        state: { eventDetails },
      });
    } else if (section === "post-event-planning") {
      navigate(`/events/post-event-planning/${defaultSubSection}`, {
        state: { eventDetails },
      });
    } else if (section === "individual") {
      navigate(`/events/individual/${defaultSubSection}`, {
        state: { eventDetails },
      });
    } else if (section === "report") {
      navigate(`/events/report/{defaultSubSection}`, {
        state: { eventDetails },
      });
    } else if (section === "Organizer") {
      navigate(`/events/Organizer/${defaultSubSection}`, {
        state: { eventDetails },
      });
    }
  };
  

  const handleSubSectionClick = (subSection, path) => {
    setActiveSubSection(subSection);
    navigate(path, { state: { eventDetails } });
  };

  const isMaterialConsumptionEnabled = eventDetails?.boundary?.includes(
    "Material Consumption"
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={`col-md-12 event-details-top-navbar ${
            isCollapsed ? "navbar-collapsed" : ""
          }`}
        >
          <ul>
            <li>
              <Link
                to="/events/event-details"
                state={{ eventDetails }}
                className={`${
                  activeButton === "Event Details" ? "navbar-link-active" : ""
                }`}
                onClick={() => handleButtonClick("Event Details", "")}
              >
                Event Details
              </Link>
            </li>
            <li>
              <Link
                to="/events/pre-event-planning"
                state={{ eventDetails }}
                className={`${
                  activeButton === "pre-event-planning"
                    ? "navbar-link-active"
                    : ""
                }`}
                onClick={() =>
                  handleButtonClick("pre-event-planning", "pre-planning")
                }
              >
                Pre-Event Planning
              </Link>
            </li>
            <li>
              <Link
                to="/events/during-event-planning"
                state={{ eventDetails }}
                className={`${
                  activeButton === "during-event-planning"
                    ? "navbar-link-active"
                    : ""
                }`}
                onClick={() =>
                  handleButtonClick("during-event-planning", "attendee-details")
                }
              >
                During Event Planning
              </Link>
            </li>
            <li>
              <Link
                to="/events/post-event-planning"
                state={{ eventDetails }}
                className={`${
                  activeButton === "post-event-planning"
                    ? "navbar-link-active"
                    : ""
                }`}
                onClick={() =>
                  handleButtonClick("post-event-planning", "waste-generation")
                }
              >
                Post-Event Planning
              </Link>
            </li>
            <li>
              <Link
                to="/events/individual"
                state={{ eventDetails }}
                className={`${
                  activeButton === "individual" ? "navbar-link-active" : ""
                }`}
                onClick={() => handleButtonClick("individual", "my-data")}
              >
                Attendee
              </Link>
            </li>
            <li>
              <Link
                to="/events/Organizer/Organizer-info"
                state={{ eventDetails }}
                className={`${
                  activeButton === "Organizer" ? "navbar-link-active" : ""
                }`}
                onClick={() => handleButtonClick("Organizer", "Organizer-info")}
              >
                Organizer
              </Link>
            </li>
            <li>
              <Link
                to="/events/report"
                state={{ eventDetails }}
                className={`${
                  activeButton === "report" ? "navbar-link-active" : ""
                }`}
                onClick={() => handleButtonClick("report", "summary-report")}
              >
                Report
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 sub-sections">
          {activeButton === "pre-event-planning" && (
            <ul>
              <li>
                <Link
                  to="/events/pre-event-planning/pre-planning"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "pre-planning"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "pre-planning",
                      "/events/pre-event-planning/pre-planning"
                    )
                  }
                >
                  <img src={Preplan} alt="Pre-Planning" />
                  Invitations & Marketing
                </Link>
              </li>
              <li>
                <Link
                  to="/events/pre-event-planning/meeting-list"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "meeting-list"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "meeting-list",
                      "/events/pre-event-planning/meeting-list"
                    )
                  }
                >
                
                  <img src={Preplan} alt="Meeting Info" />
                  Meeting Info
                </Link>
              </li>
            </ul>
          )}
          {activeButton === "during-event-planning" && (
            <ul>
              <li>
                <Link
                  to="/events/during-event-planning/attendee-details"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "attendee-details"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "attendee-details",
                      "/events/during-event-planning/attendee-details"
                    )
                  }
                >
                  <img src={Attendee} alt="Attendee Details" />
                  Attendee Details
                </Link>
              </li>
              <li>
                <Link
                  to="/events/during-event-planning/energy-consumption"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "energy-consumption"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "energy-consumption",
                      "/events/during-event-planning/energy-consumption"
                    )
                  }
                >
                
                  <img src={Energy} alt="Energy Consumption" />
                  Energy Consumption
                </Link>
              </li>
              <li>
                <Link
                  to="/events/during-event-planning/meal-consumption"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "meal-consumption"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "meal-consumption",
                      "/events/during-event-planning/meal-consumption"
                    )
                  }
                >
                  <img src={Meal} alt="Meal Consumption" />
                  Meal Consumption
                </Link>
              </li>
              {eventDetails.isShuttleService && (
                <li>
                  <Link
                    to="/events/during-event-planning/shuttle-transport"
                    state={{ eventDetails }}
                    className={
                      activeSubSection === "shuttle-transport"
                        ? "navbar-subsection-active"
                        : ""
                    }
                    onClick={() =>
                      handleSubSectionClick(
                        "shuttle-transport",
                        "/events/during-event-planning/shuttle-transport"
                      )
                    }
                  >
                    <img src={Meal} alt="Meal Consumption" />
                    Shuttle/Local Transport
                  </Link>
                </li>
              )}

              {isMaterialConsumptionEnabled && (
                <li>
                  <Link
                    to="/events/during-event-planning/material-consumption"
                    state={{ eventDetails }}
                    className={
                      activeSubSection === "material-consumption"
                        ? "navbar-subsection-active"
                        : ""
                    }
                    onClick={() =>
                      handleSubSectionClick(
                        "material-consumption",
                        "/events/during-event-planning/material-consumption"
                      )
                    }
                  >
                    <img src={Material} alt="Material Consumption" />
                    Material Consumption
                  </Link>
                </li>
              )}
            </ul>
          )}
          {activeButton === "post-event-planning" && (
            <ul>
              <li>
                <Link
                  to="/events/post-event-planning/waste-generation"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "waste-generation"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "waste-generation",
                      "/events/post-event-planning/waste-generation"
                    )
                  }
                >
                  <img src={Waste} alt="Waste Generation" />
                  Waste Generation
                </Link>
              </li>
            </ul>
          )}
          {activeButton === "individual" && (
            <ul>
              <li>
                <Link
                  to="/events/individual/my-data"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "my-data"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "my-data",
                      "/events/individual/my-data"
                    )
                  }
                >
                  <img src={Mydata} alt="My Data" />
                  My Information
                </Link>
              </li>
            </ul>
          )}
          {activeButton === "Organizer" && (
            <ul>
              <li>
                <Link
                  to="/events/Organizer/Organizer-info"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "Organizer-info"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "Organizer-info",
                      "/events/Organizer/Organizer-info"
                    )
                  }
                >
                  <img src={Mydata} alt="Organizer Info" />
                  Organizer's Information
                </Link>
              </li>
            </ul>
          )}
          {activeButton === "report" && (
            <ul>
              <li>
                <Link
                  to="/events/report"
                  state={{ eventDetails }}
                  className={
                    activeSubSection === "summary-report"
                      ? "navbar-subsection-active"
                      : ""
                  }
                  onClick={() =>
                    handleSubSectionClick(
                      "summary-report",
                      "/events/report"
                    )
                  }
                >
                  <img src={Mydata} alt="Summary Report" />
                  Summary Report
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDetailsSidebar;
