import { useLocation, Route, Routes } from "react-router-dom";
import EcoIndex from "./EcoIndex";
import EventDetailsSidebar from "./eventDetailsSidebar";
import AttendeeDetaileventDetailsSidebar from "./AttendeeDetaileventDetailsSidebar";
import MyData from "./MyData";
import "./Individual.css";
import React, { useEffect } from "react";
import { useEvent } from "./EventDetailsContext";
import OrganiserDetailsSidebar from "./OragniserDetailsSidebar";

const Individual = () => {
  const location = useLocation();
  const { eventDetails, setEventDetails } = useEvent();

  useEffect(() => {
    if (location.state?.event) {
      setEventDetails(location.state.event);
    }
  }, [location.state, setEventDetails]);

  if (!eventDetails) {
    return <div>Loading event details...</div>;
  }

  return (
    <div className="individual">
      <EcoIndex />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center mb-3">
              <div class="main-content-header">UPDATE EVENT</div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end mb-3">
              <h1>{eventDetails?.eventName}</h1>
            </div>
          </div>
          <hr />
          <div className="row p-3">
            <div className="col-md-12 p-0 shadow-box">
              <div className="row">
                <div className="col-md-12">
                  {eventDetails?.myRole === "Attendee" ? (
                    <AttendeeDetaileventDetailsSidebar eventDetails={eventDetails} />
                  ) : eventDetails.myRole === "Organizer" ? (
                    <OrganiserDetailsSidebar eventDetails={eventDetails} />
                  ) : (
                    <EventDetailsSidebar eventDetails={eventDetails} />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="individual-content">
                    <Routes>
                      <Route
                        path="my-data"
                        element={<MyData eventDetails={eventDetails} />}
                      />
                      {/* Add other routes here */}
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Individual;
