// EventContext.js
import React, { createContext, useContext, useState } from "react";

const EventContext = createContext();

export const useEvent = () => useContext(EventContext);

export const EventProvider = ({ children }) => {
  const [eventDetails, setEventDetails] = useState(null);
  const updateEventDetails = (newEventDetails) => {
    setEventDetails(newEventDetails);
  };
  return (
    <EventContext.Provider
      value={{ eventDetails, setEventDetails, updateEventDetails }}
    >
      {children}
    </EventContext.Provider>
  );
};
