import React, { useEffect, useState } from "react";
import { getOrganizerEmission, getIndividualEmission } from "./apiService";
import OrganizerPieChart from "./OrganizerPieChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const OrganizerTotal = ({ eventId }) => {
  const [emissionData, setEmissionData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEmissionData = async () => {
      setLoading(true);
      try {
        const organizerData = await getOrganizerEmission(eventId);

        setEmissionData(organizerData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEmissionData();
  }, [eventId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  const formatEmission = (value) => value?.toFixed(2);

  const totalEmission = emissionData?.totalCarbonEmission || 0;

  const EmissionSources = ({ emissionData }) => {
    const totals = emissionData.mainData.reduce(
      (acc, item) => {
        if (item.emissionType === "Travel") {
          acc.travel += item.value;
        } else if (item.emissionType === "Accommodation") {
          acc.accommodation += item.value;
        }
        return acc;
      },
      { travel: 0, accommodation: 0 }
    );
    return (
      <>
        <div className='row'>
          <div className="col-md-8"><p>Emissions from travel: </p></div>
          <div className="col-md-4"><p className='font-weight-light'>{totals.travel} KgCO₂</p></div>
        </div>
        <div className='row'>
          <div className="col-md-8"><p>Emissions from accommodation: </p></div>
          <div className="col-md-4"><p className='font-weight-light'>{totals.accommodation} KgCO₂</p></div>
        </div>
      </>
    )
  }


  return (
    <div className="row">
      <div className="col-md-8">
        <OrganizerPieChart eventId={eventId} />
      </div>
      <div className='col-md-4'>
        <div className="row">
          <div className="col-md-12">
            <div className="DashbordNoBox">
              <div className="dashboard-card-header">
                <div className="dashboard-card-header-title">
                  <h3>My Contribution in Total Carbon Emission</h3>
                </div>
                <div className="dashboard-card-header-image">
                  <p className="dashboard-image">
                    <FontAwesomeIcon icon={faUser} className="icon-shadow" />
                  </p>
                </div>
              </div>
              <div className="col-md-12 dashboard-value">
                <p>
                  {totalEmission > 0
                    ? `${formatEmission(totalEmission)} KgCO₂`
                    : "No data available"}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="attendee-emission-card mt-4">
              <div className="attendee-card-header">
                <div className="attendee-card-header-title">
                  <h3>Emission Sources</h3>
                </div>
              </div>
              <div className="col-md-12 attendee-value">
                {emissionData.mainData.length ? (
                  <EmissionSources emissionData={emissionData} />
                ) : <p>No data available</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizerTotal;
