// src/components/Login.js
import React, { useEffect } from 'react';
import userManager from './oidc-config';

const Login = () => {
  useEffect(() => {
    const u = userManager.signinRedirect();
    console.log('user', u)
  }, []);

  return (
    <div className='loader-ecoindex'></div>
  );
};

export default Login;
