// src/PreEventPlanning.js
import { useLocation, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import EcoIndex from "./EcoIndex";
import EventDetailsSidebar from "./eventDetailsSidebar";
import PrePlanning from "./PrePlanning";
import MeetingInfo from "./components/meetings/MeetingInfo";
import AddMeeting from "./components/meetings/AddMeeting";
import MeetingList from "./components/meetings/MeetingList";
import UploadMeetingSummary from "./components/meetings/UploadMeetingSummary";
import "./PreEventPlanning.css";
import { useEvent } from "./EventDetailsContext";
import MeetingSummary from "./components/meetings/MeetingSummary";

const PreEventPlanning = () => {
  const location = useLocation();
  const { eventDetails, setEventDetails } = useEvent();

  useEffect(() => {
    if (location.state?.event) {
      setEventDetails(location.state.event);
    }
  }, [location.state, setEventDetails]);


  return (
    <div className="pre-planning">
      <EcoIndex />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center mb-3">
              <div class="main-content-header">UPDATE EVENT</div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-end mb-3">
              <h1>{eventDetails?.eventName}</h1>
            </div>
          </div>
          <hr />
          <div className="row p-3">
            <div className="col-md-12 p-0 shadow-box">
              <div className="row">
                <div className="col-md-12">
                  <EventDetailsSidebar eventDetails={eventDetails} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="pre-planning-content">
                    <Routes>
                      <Route path="pre-planning" element={<PrePlanning />} />
                      <Route path="/meetinginfo/*" element={<MeetingInfo />} />
                      <Route path="/add-meetings" element={<AddMeeting />} />
                      <Route path="/meeting-list" element={<MeetingList />} />
                      <Route path="/upload-meeting-summary" element={<UploadMeetingSummary />} />
                      <Route path="/meeting-summary" element={<MeetingSummary />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default PreEventPlanning;
