import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

const MealConsumptionDoughnutChart = ({ attendeeMealConsumptions }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#a18cd1',
          '#ff9a9e',
          '#8fd3f4',
          '#a1c4fd',
          '#8de9d5',
        ],
      },
    ],
  });

  const mealMapping = {
    6: 'Veg',
    7: 'Non-Veg',
    8: 'Vegan',
    9: 'No Meal',
  };

  useEffect(() => {
    if (attendeeMealConsumptions && attendeeMealConsumptions.length > 0) {
      const mealData = {};

      attendeeMealConsumptions.forEach((consumption) => {
        const mealType = mealMapping[consumption.mealId] || `Meal ${consumption.mealId}`;
        if (!mealData[mealType]) {
          mealData[mealType] = 0;
        }
        mealData[mealType] += consumption.attendeeCount;
      });

      setChartData({
        labels: Object.keys(mealData),
        datasets: [
          {
            data: Object.values(mealData),
            backgroundColor: [
              '#a18cd1',
              '#ff9a9e',
              '#8fd3f4',
              '#a1c4fd',
              '#8de9d5',
            ],
            borderWidth: 3,
            borderRadius: 8,
            cutout: '85%',
            borderColor: "#e4e8f6",
            hoverBackgroundColor: [
              '#a18cd1',
              '#ff9a9e',
              '#8fd3f4',
              '#a1c4fd',
              '#8de9d5',
            ],
            hoverBorderWidth: 3,
            hoverBorderColor: "#e4e8f6",
            hoverOffset: 6,
            spacing: 3,
          },
        ],
      });
    }
  }, [attendeeMealConsumptions]);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (context.parsed) {
              const formattedValue = context.parsed.toFixed(2); // Format value to two decimal places
              label += `: ${formattedValue} KgCO₂`;
            }
            return label;
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: '#ffffff',
        bodyColor: '#ffffff',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 18,
          color: '#36454F',
          font: {
            size: 12,
          }
        },
        align: 'start',
      }
    }
  };

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className="chart-container">
          <div className='chart-header'>
            <h3>Emissions- Meal Consumption Distribution</h3>
          </div>
          <div className='chart-data'>
            <Doughnut data={chartData} />
          </div>
        </div>
      </div>
      <div className='col-md-6 d-flex align-items-center'>
        <div style={{ flex: 1, overflowX: 'auto' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '12px' }}>
            <thead>
              <tr>
                <th>Meal Type</th>
                <th>Attendee Count</th>
              </tr>
            </thead>
            <tbody>
              {attendeeMealConsumptions && attendeeMealConsumptions.length > 0 ? (
                attendeeMealConsumptions.map((consumption) => {
                  const mealType = mealMapping[consumption.mealId] || `Meal ${consumption.mealId}`;
                  return (
                    <tr key={consumption.attendeeMealConsumptionId}>
                      <td>{mealType}</td>
                      <td>{consumption.attendeeCount}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="2">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MealConsumptionDoughnutChart;
