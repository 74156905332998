import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import "./pricing.css";

const PricingCards = () => {
  return (
    <div className="pricing-table-container">
    <table className="pricing-table">
      <thead>
        <tr>
          <th className="feature-column">
            <div className="compare-section">
              <h4>Compare Plans 🍃</h4>
            
              <p>Choose your workspace plan according to your organizational need.</p>
            </div>
          </th>
          <th>
            <div className="pricing-header">
              <div className="event-type">
              <h2>Explorer</h2>
              <div className="billed-text">
              <span>No Cost</span>
            
              </div>
              
              </div>
              <button className="choose-plan-btn">Choose This Plan</button>
            </div>
          </th>
          <th>
            <div className="pricing-header">
              <div className="event-type">
              <h2>Professional</h2>
              <div className="billed-text">
              <span>8,500/month</span>
              <h4>Billed Annually</h4>
              </div>
              </div>
              <button className="choose-plan-btn">Choose This Plan</button>
            </div>
          </th>
          <th>
            <div className="pricing-header">
              <div className="event-type">
              <h2>Premium</h2>
              <div className="billed-text">
              <span>18,000/month</span>
              <h4>Billed Annually</h4>
              </div>
              </div>
              <button className="choose-plan-btn">Choose This Plan</button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        
        <tr>
          <td>Participants</td>
          <td>100</td>
          <td>1000</td>
          <td>1000 & Above</td>
         
         
 </tr>
        
       

        <tr>
          <td>Carbon Emission Estimation</td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
        </tr>

        <tr>
          <td>No. of Events</td>
          <td>1</td>
          <td>6</td>
          <td>24</td>
        </tr>

        <tr>
          <td>Automated Email Notifications</td>
          <td>100 emails</td>
          <td>3000 emails</td>
          <td>Unlimited</td>
        </tr>

        <tr>
          <td>Report Generation & Downloading</td>
          <td><FontAwesomeIcon icon={faXmark} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
        </tr>

        <tr>
          <td>AI-Powered Advisory</td>
          <td><FontAwesomeIcon icon={faXmark} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
        </tr>

        <tr>
          <td>API Access</td>
          <td><FontAwesomeIcon icon={faXmark} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
        </tr>
        <tr>
        <td>API Integration Support</td>
          <td><FontAwesomeIcon icon={faXmark} /></td>
          <td><FontAwesomeIcon icon={faXmark} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
        </tr>
       
        <tr>
        <td>Consulting for Carbon Neutral Event</td>
          <td><FontAwesomeIcon icon={faXmark} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
        </tr>
        <tr>
        <td>Report Validation Options</td>
          <td><FontAwesomeIcon icon={faXmark} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
        </tr>
        <tr>
          <td>Carbon Offset Plans </td>
          <td><FontAwesomeIcon icon={faXmark} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
          <td><FontAwesomeIcon icon={faCheck} /></td>
        </tr>
      </tbody>
    </table>

<div className="single-event">
  <h4>Or You may select per event basis</h4>
  <p> <b>Single Event Plan:</b> <i> Rs.30,000 </i>(All facilities of Premium Level Plan for 1 event only) <br></br>
 <b> Customized Plan:</b> Send your enquiry to  📧 <a href="mailto:connect@ecoindex.ai">connect@ecoindex.ai</a> to get quote.</p>
</div>

  </div>
  );
};

export default PricingCards;
