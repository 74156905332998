import "./MeetingList.css";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getMeetingList,
  deleteMeeting,
  getMeetingSummary,
} from "../../apiService";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useEvent } from "../../EventDetailsContext";
import AddMeeting from "./AddMeeting";
import UploadMeetingSummary from "./UploadMeetingSummary";
import { Modal, Button, Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Collapse } from "@mui/material";
import MeetingSummary from "./MeetingSummary";
import CustomAlert from "../../CustomAlert";
import EventName from "../../EventName";
import { QRCodeCanvas } from "qrcode.react";
import logoSrc from "../../assets/ecologo.png";

const MeetingList = () => {
  const [meetings, setMeetings] = useState([]);
  const [selectedMeetings, setSelectedMeetings] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [confirmationAction, setConfirmationAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [filledCount, setFilledCount] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const { eventDetails } = useEvent();
  const eventId = eventDetails ? eventDetails.eventId : null;
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [showCancel, setShowCancel] = useState(false);
  const [meetingLocked, setMeetingLocked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!eventDetails) {
          throw new Error("Event details not available");
        }
        const eventId = eventDetails.eventId;
        const data = await getMeetingSummary(eventId);
        setMeetingLocked(data.isMeetinglocked);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [eventDetails, meetings]);

  const handleFillSummary = () => {
    navigate("/events/pre-event-planning/upload-meeting-summary");
  };

  const handleCreateMeeting = () => {
    navigate("/events/pre-event-planning/add-meetings");
  };

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        if (!eventDetails) {
          throw new Error("Event details not available");
        }

        console.log("Fetching meetings for EventId:", eventId);
        const data = await getMeetingList(eventId);
        console.log("Meeting List Data:", data);
        if (data && Array.isArray(data)) {
          setMeetings(data);
        } else {
          console.log("Unexpected data format or empty data:", data);
        }
      } catch (error) {
        console.error("Error fetching meeting data:", error);
      }
    };

    fetchMeetings();
  }, [eventDetails]);

  useEffect(() => {
    console.log("Meetings state updated:", meetings);
  }, [meetings]);

  const handleAddMeetingClick = (meeting) => {
    if (meeting) {
      navigate("/events/pre-event-planning/add-meetings", {
        state: { meeting },
      });
    }
  };

  const handleCheckBoxChange = (meetingId) => {
    setSelectedMeetings((prevSelectedMeetings) => {
      if (prevSelectedMeetings.includes(meetingId)) {
        return prevSelectedMeetings.filter((id) => id !== meetingId);
      } else {
        return [...prevSelectedMeetings, meetingId];
      }
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;
      if (newSelectAll) {
        setSelectedMeetings(meetings.map((meeting) => meeting.id));
      } else {
        setSelectedMeetings([]);
      }
      return newSelectAll;
    });
  };

  const handleDeleteMeetings = () => {
    if (selectedMeetings.length === 0) {
      setAlertMessage("Please select a meeting");
      setAlertType("error");
      setShowAlert(true);
      setShowCancel(false);
      return;
    }
    setAlertMessage("Are you sure you want to delete the selected meetings?");
    setAlertType("warning");
    setShowAlert(true);
    setShowCancel(true);
    setConfirmationAction(() => confirmDelete);
  };

  const confirmDelete = async () => {
    try {
      await deleteMeeting(selectedMeetings);
      const data = await getMeetingList(eventId);
      setMeetings(data);
      setSelectedMeetings([]);
      setAlertMessage("Selected meetings deleted successfully.");
      setAlertType("success");
      setSelectAll(false);
    } catch (error) {
      console.error("Error deleting meetings:", error);
      setAlertMessage("An error occurred while deleting meetings.");
      setAlertType("error");
    } finally {
      setShowAlert(true);
      setShowCancel(false);
      setConfirmationAction(null);
    }
  };

  const linkto = `${process.env.REACT_APP_BASE_URL}add-information?id=${eventDetails.eventId}`;

  const handleShow = (meetingStatus) => {
    const updatedUserDetails = meetingStatus.map((status) => ({
      email: status.userEmail,
      name: status.name,
      filled: status.accommodationId !== null,
    }));
    setUserDetails(updatedUserDetails);
    setFilledCount(updatedUserDetails.filter((user) => user.filled).length);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);
  const handleCloseAlert = () => {
    setShowAlert(false);
    setShowCancel(false);
  };

  const renderTooltip = (msg) => <Tooltip id="button-tooltip">{msg}</Tooltip>;

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col-md-12 text-center">
          <h1 className="page-title">Meeting Management</h1>
          <p className="page-description">
            This page allows you to manage your meetings. You can create new
            meetings, fill in meeting summaries, and view existing meetings.
          </p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-12 d-flex justify-content-center align-items-center">
          <div className="button-group">
            <button
              className="btn GreenBtn"
              onClick={handleCreateMeeting}
              disabled={meetingLocked}
              title={
                meetingLocked
                  ? "Cannot create meeting after filling summary"
                  : "Create an Online/Offline meeting"
              }
            >
              Create Meeting
            </button>
            <button
              className="btn GreenBtn mr-4"
              onClick={handleFillSummary}
              title={"You can directly fill in the summary here."}
            >
              Fill Summary
            </button>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-12 d-flex align-items-center">
          {meetings.length > 0 && !meetingLocked && (
            <div className="deleteBtn-container">
              <i
                className="fa fa-trash"
                aria-hidden="true"
                onClick={handleDeleteMeetings}
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  color: "#dc3545",
                }}
              ></i>
              <span className="delete-tooltip">Delete</span>
            </div>
          )}
        </div>
      </div>

      {meetings.length > 0 && !meetingLocked && (
        <div className="row mb-4">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="text-center">
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                      />
                    </th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {meetings.length > 0 ? (
                    meetings.map((meeting) => {
                      const filled = meeting.status.filter(
                        (user) => user.accommodationId !== null
                      ).length;
                      const totalUsers = meeting.status.length;
                      return (
                        <tr key={meeting.id}>
                          <td align="center">
                            <input
                              type="checkbox"
                              checked={selectedMeetings.includes(meeting.id)}
                              onChange={() => handleCheckBoxChange(meeting.id)}
                            />
                          </td>
                          <td>{meeting.name}</td>
                          <td>{meeting.meetingType}</td>
                          <td>{meeting.meetingStartDate}</td>
                          <td>
                            <button
                              className="btn view-meeting"
                              onClick={() => handleAddMeetingClick(meeting)}
                            >
                              Edit
                            </button>
                          </td>
                          <td>
                            {meeting.meetingType === "offline" && (
                              <>
                                <Button
                                  className="custom-button"
                                  variant="primary"
                                  onClick={() => handleShow(meeting.status)}
                                >
                                  {filled}/{totalUsers}
                                </Button>
                                <Modal
                                  show={showModal}
                                  onHide={handleClose}
                                  dialogClassName="meeting-modal"
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      User Details ({filledCount}/
                                      {userDetails.length} Filled)
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Table striped bordered hover>
                                      <thead>
                                        <tr>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {userDetails.map((user, index) => (
                                          <tr key={index}>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>
                                              {user.filled
                                                ? "Filled"
                                                : "Not Filled"}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleClose}
                                    >
                                      Close
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" align="center">
                        No meetings available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-12 meeting-summary mt-5">
          <div
            className="row"
            style={{ cursor: "pointer" }}
            onClick={() => setShowSummary(!showSummary)}
          >
            <div className="col-md-6 show-summary mb-0">Summary</div>
            <div className="col-md-6 d-flex justify-content-end align-items-center mb-0">
              {showSummary ? (
                <ExpandMoreOutlinedIcon />
              ) : (
                <KeyboardArrowLeftIcon />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-">
              <Collapse in={showSummary}>
                <MeetingSummary key={meetings.length} meetings={meetings} />
              </Collapse>
            </div>
          </div>
        </div>
      </div>

      {showAlert && (
        <CustomAlert
          message={alertMessage}
          type={alertType}
          onClose={handleCloseAlert}
          onConfirm={confirmationAction}
          showCancel={showCancel}
        />
      )}
    </div>
  );
};

export default MeetingList;
