import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import sprout from "./assets/sprout.png";
import weight from "./assets/weight.png";
import './Followecoindex.css';

const FollowEcoIndex = () => {
  return (
    <div className="eco eco1">
      <div className='followtwonav'>
        <div>
          <div className='followtwonav-left'>
            <img src={sprout} className="ecos-logo" alt="Ecoindex logo" />
          </div>

          <div className='core'>
          
            <div className='pic'>
              <img src={weight} alt="Scales illustration" />
            </div>
            
       
            <div className="imp-text">
              <h1 className="text-center">Stay Connected!</h1>
              <h3 className="text-center">
                Follow us on social media for the latest updates and behind-the-scenes content.
              </h3>
              <div className='centerdiv'>
                <a href='https://ecoindex.ai/' target='_blank' rel="noopener noreferrer">
                  <i className="fa fa-3x fa-link icon" />
                </a>
                <a href='https://x.com/Ecoindex_ai' target='_blank' rel="noopener noreferrer">
                  <i className="fa fa-3x fa-twitter icon" />
                </a>
                <a href='https://www.instagram.com/ecoindex_ai/' target='_blank' rel="noopener noreferrer">
                  <i className="fa fa-3x fa-instagram icon" />
                </a>
                <a href='https://www.linkedin.com/company/ecoindex' target='_blank' rel="noopener noreferrer">
                  <i className="fa fa-3x fa-linkedin icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowEcoIndex;
